<template>
  <div class="online-detail-dialog">
    <w-dialog ref="dialogRef" title="上线配置" width="60%" top="15vh" @wConfirm="handleSure">
      <div class="checkbox-container">
        <p>请选择上线园区</p>
        <el-checkbox-group v-model="formData.scenic">
          <el-checkbox :label="item.s_id" v-for="(item, index) in scenicData" :key="index">{{ item.s_name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref } from "vue";
  import { BasicApi } from "@/plugins/api.js";
  // import { ElMessage } from "element-plus";
  export default {
    emits: ["submit"],
    setup(props, { emit }) {
      const dialogRef = ref(null);
      const scenicData = ref([]);

      function getAllScenicData() {
        BasicApi.getAllScenicData().then((res) => {
          scenicData.value = res.Data;
        });
      }
      const formData = ref({
        scenic: [],
      });
      const id = ref(null);
      function openDialog(rowData) {
        id.value = rowData.mp_id;
        getAllScenicData();
        const selestScenic = [];

        rowData.miniSourceScenic &&
          rowData.miniSourceScenic.forEach((el) => {
            selestScenic.push(el.mss_sid);
          });

        formData.value = {
          scenic: selestScenic,
        };
        dialogRef.value.show();
      }
      function closeDialog() {
        dialogRef.value.close();
      }
      function closeDialogLoading() {
        dialogRef.value.isLoading = false;
      }
      function handleSure() {
        dialogRef.value.isLoading = true;
        const lastData = {
          mp_id: id.value,
          ...formData.value,
        };
        emit("submit", lastData);
      }
      return {
        handleSure,
        openDialog,
        dialogRef,
        closeDialog,
        formData,
        scenicData,
        closeDialogLoading
      };
    },
  };
</script>

<style lang="scss">
  .online-detail-dialog {
    p {
      color: var(--text-third-color);
    }

    .checkbox-container {
      border-bottom: 1px solid var(--search-bg-color);
      margin-bottom: 20px;

      .el-checkbox-group {
        padding: 8px 0;
      }
    }

    .el-dialog__body {
      .net-config-content {
        height: 400px;
        overflow-y: auto;
      }
    }
  }
</style>