<!-- 小程序管理 -->

<template>
  <div class="minimanage main-cnt">
    <div class="title">小程序列表</div>
    <div class="content">
      <common-table ref="miniTable" tableHeight="calc(100vh - 270px)" :ischeck="false" :ispaging="true"
        :apiName="BasicApi.miniList" :columns="tableColumns" @config="openConfigDialog" @showAlbum="openOnlineDialog"
        @agreement="agreement"></common-table>
    </div>

    <!-- 上线明细 -->
    <OnlineDialog @submit="handleOnline" ref="onlineDialogRef"></OnlineDialog>

    <!-- 配置 -->
    <ConfigDialog ref="configDialogRef"></ConfigDialog>

    <!-- 协议 -->
    <w-dialog ref="guideDialog" class="guide-dialog" title="协议内容" width="60%" btnWidth="140px" top="10vh"
      confirmText="确认编辑" @wConfirm="guideConfirm" :hideFooter="authData.indexOf('mp_b7Z8oo9bWvN1mRMbClX9Cfj4') == '-1'">
      <div style="margin-bottom: 15px;color:var(--top-text-color);">协议内容</div>
      <div ref="guideEditorElem"></div>
    </w-dialog>
  </div>
</template>
<script setup>
  import { ref, onMounted, computed, watch } from "vue";
  import OnlineDialog from "../components/OnlineDialog.vue";
  import ConfigDialog from "../components/ConfigDialog.vue";
  import { ElMessage, ElLoading } from "element-plus";
  import { BasicApi } from "@/plugins/api.js";
  import { useStore } from "vuex";
  // 引入富文本编辑器
  import Editor from "wangeditor";

  const store = useStore();
  const qiniuData = computed(() => store.state.Config.qiniuData);
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "mp_id",
      label: "序号",
      minWidth: 80,
      color: "--text-third-color",
    },
    {
      prop: "mp_name",
      label: "小程序名称",
      minWidth: 120,
      color: "--text-color",
    },
    {
      type: "block",
      prop: "number",
      label: "上线明细",
      minWidth: 80,
      token: "mp_yEBrDBqWsN4J6ndhp11TAZuBDX",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 200,
      bottons: [
        {
          name: "配置",
          action: "config",
          token: "mp_FEo2p5y0Xd95XRp4p4LpcyyYqw",
          className: "theme-font-btn",
        },
        {
          name: "协议",
          action: "agreement",
          token: "mp_fiEL3l59oVL3unUrZg8IV7aTLcV",
          className: "theme-font-btn",
        },
      ],
    },
  ]);
  /** 入口表格对象 */
  const miniTable = ref(null);
  const onlineDialogRef = ref(null);
  /** 点击 上线明细 */
  const openOnlineDialog = (row) => {
    onlineDialogRef.value.openDialog(row);
  };
  function handleOnline(data) {
    BasicApi.miniDetailSet(data).then((res) => {
      onlineDialogRef.value.closeDialogLoading();
      if (res.Code === 200) {
        onlineDialogRef.value.closeDialog();
        miniTable.value.tableLoad();
        ElMessage.success("操作成功");
      } else {
        let msg = res.Message ? res.Message : "操作失败";
        ElMessage.error(msg);
      }
    });
  }
  /** 配置 弹框 */
  const configDialogRef = ref(null);
  /** 当前操作行 */
  const currentRow = ref(null);
  /** 表格 配置 */
  const openConfigDialog = (row) => {
    configDialogRef.value.openDialog(row);
  };

  /**
   * 
   * 查看协议
   * 
   * **/
  const agreement = (row) => {
    // 当前行数据
    currentRow.value = row;// 打开对话框
    guideDialog.value.show();
    // 获取协议数据
    getScenicInfo({ mp_id: row.mp_id });
  }
  /**
   * 
   * 获取协议数据
   * 
   * **/
  const getScenicInfo = (data, type) => {
    BasicApi.getAgreement(data).then((res) => {
      if (res.Code === 200) {
        // 获取七牛云token
        store.dispatch("getQiniuData");
        // 处理图片
        guideEditorImage.value = res.Data.mp_user_agreement.image || [];
        // 初始化富文本
        guideEditorInit();
        // 富文本内容
        guideEditor.value.txt.html(res.Data.mp_user_agreement);
      } else {
        let msg = res.Message ? res.Message : "获取协议数据失败！";
        ElMessage.error(msg);
      }
    });
  };
  // 协议对话框对象
  const guideDialog = ref(null);
  // 富文本容器
  const guideEditorElem = ref(null);
  // 富文本图片
  const guideEditorImage = ref([]);
  // 富文本实例对象
  const guideEditor = ref(null);
  /**
   * 
   * 富文本初始化
   * 
   * **/
  const guideEditorInit = () => {
    guideEditor.value = new Editor(guideEditorElem.value);
    guideEditor.value.config.colors = [
      "#000000",
      "#eeece0",
      "#1c487f",
      "#4d80bf",
      "#0072f6",
      "#1a1a1a",
      "#FFB900",
      "#EB5774",
      "#f2f2f2",
      "#666666",
      "#999999",
      "#cccccc",
    ];
    // 自定义菜单配置
    guideEditor.value.config.menus = [
      "head",
      "bold",
      "fontSize",
      "fontName",
      "italic",
      "underline",
      "strikeThrough",
      "indent",
      "lineHeight",
      "foreColor",
      "justify",
      "quote",
      "splitLine",
      "image",
      "undo",
      "redo",
    ];
    // 限制上传图片格式
    guideEditor.value.config.uploadImgAccept = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
    ];
    guideEditor.value.config.qiniu = true;
    guideEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
    const upToken = qiniuData.value.token;
    const domain = qiniuData.value.http_domain;
    guideEditor.value.config.customUploadImg = (file) => {
      if (file.length) {
        file.forEach((el) => {
          const myDate = new Date();
          const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
          const timeData = Date.parse(new Date()); //当前时间时时间戳
          const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
          const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
          const filename = el.name;
          const index = filename.lastIndexOf(".");
          const suffix = filename.substr(index + 1);
          const key = `play-guide-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
          const config = {
            // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            cdnUphost: domain,
          };
          const putExtra = {
            fname: "", //文件原文件名
            params: {}, //用来放置自定义变量
            mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
          };
          const observable = Qiniu.upload(el, key, upToken, putExtra, config);
          observable.subscribe({
            error: () => { },
            complete: (res) => {
              const sourceLink = domain + res.key;
              guideEditor.value.cmd.do(
                "insertHtml",
                '<img src="' + sourceLink + '" style="max-width:100%;"/>'
              );
              guideEditorImage.value.push({
                filename: filename,
                qiniu_key: res.key,
                size: el.size,
              });
            },
          });
        });
      }
    };
    // 富文本高度
    guideEditor.value.config.height = 400;
    // 创建富文本实例
    guideEditor.value.create();
  };
  /**
   * 
   * 协议编辑确认按钮
   * 
   * **/
  const guideConfirm = () => {
    guideDialog.value.isLoading = true;
    let data = {
      mp_id: currentRow.value.mp_id,
      mp_user_agreement: guideEditor.value.txt.html(),
    };
    BasicApi.editAgreement(data).then((res) => {
      guideDialog.value.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success("编辑成功！");
        guideDialog.value.close();
        // 重新获取数据
        miniTable.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "编辑失败！";
        ElMessage.error(msg);
      }
    });
  };
  onMounted(() => {
    // 获取闸机表格数据
    miniTable.value.tableLoad();
  });
</script>

<style lang="scss">
  .minimanage {
    font-family: "Source Han Sans CN";

    .content {
      padding: 20px;
    }

    .config-dialog {
      .el-dialog {
        min-width: 840px;

        .el-dialog__body {
          padding: 5px 15px 80px;

          .add-form {
            display: flex;
            flex-wrap: wrap;

            .el-form-item {
              width: 33.3%;
              padding: 25px 15px 0;
              margin-bottom: 0;

              .el-form-item__content {
                .el-select {
                  width: 100%;

                  .el-input {
                    width: 100%;
                  }
                }

                .el-date-editor {
                  width: 100%;

                  .el-range-input {
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>